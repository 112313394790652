import React from "react";

import { FluidObject } from "gatsby-image";
import { Helmet } from "react-helmet";

import { Fragment } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import clsx from "clsx";

import parse from "html-react-parser";

import { config } from "@fortawesome/fontawesome-svg-core";
import { faChevronLeft } from "@fortawesome/pro-regular-svg-icons";
import { faChevronRight } from "@fortawesome/pro-regular-svg-icons";
import { graphql } from "gatsby";

import Breadcrumbs from "@Js/Component/Breadcrumbs/Breadcrumbs";
import Link from "@Js/Component/Link/Link";
import PostGrid from "@Js/Component/PostGrid/PostGrid";
import PostImageGrid from "@Js/Component/PostImageGrid/PostImageGrid";

import classNames from "@Css/Component/Template/Category.module.scss";

config.autoAddCss = false;

export type CategoryProps = Readonly<{
    data: {
        allWordpressPost: {
            edges: {
                node: {
                    acf: {
                        read: string;
                    };
                    categories: {
                        name: string;
                        wordpress_id: number;
                    }[];
                    date: string;
                    excerpt: string;
                    featured_media?: {
                        localFile: {
                            childImageSharp: {
                                fluid: FluidObject;
                            };
                        };
                    };
                    slug: string;
                    title: string;
                    wordpress_id: number;
                };
            }[];
        };
    };
    pageContext: {
        basePath: string;
        categoryEdge: {
            node: {
                description: string;
                name: string;
                yoast_breadcrumbs: {
                    text: string;
                    url: string;
                }[];
                yoast_head: string;
            };
        };
        current: number;
        first: number;
        last: number;
        limit: number;
        next: number | null;
        previous: number | null;
    };
}>;

export const pageQuery = graphql`
    query($ids: [String!]!, $limit: Int!, $skip: Int!) {
        allWordpressPost(filter: { categories: { elemMatch: { id: { in: $ids } } } }, limit: $limit, skip: $skip) {
            edges {
                node {
                    acf {
                        read
                    }
                    categories {
                        name
                        wordpress_id
                    }
                    date(formatString: "DD MMM YYYY", locale: "NL-nl")
                    excerpt
                    featured_media {
                        localFile {
                            childImageSharp {
                                fluid(maxWidth: 1920) {
                                    ...GatsbyImageSharpFluid_withWebp
                                }
                            }
                        }
                    }
                    slug
                    title
                    wordpress_id
                }
            }
        }
    }
`;

const Category = (props: CategoryProps) => {
    const postEdges = props.data.allWordpressPost.edges;
    const pageContext = props.pageContext;

    const range = 2;
    const margin = 2;

    const items: number[] = [];

    if (pageContext.last <= range) {
        for (let i = 0; i < pageContext.last; i++) {
            items.push(i + 1);
        }
    }
    else {
        let leftSide = range / 2;
        let rightSide = range - leftSide;

        if (pageContext.current > pageContext.last - range / 2) {
            rightSide = pageContext.last - pageContext.current;
            leftSide = range - rightSide;
        } else if (pageContext.current < range / 2) {
            leftSide = pageContext.current;
            rightSide = range - leftSide;
        }

        for (let i = 0; i < pageContext.last; i++) {
            if (i + 1 <= margin) {
                items.push(i + 1);

                continue;
            }

            if (i + 1 > pageContext.last - margin) {
                items.push(i + 1);

                continue;
            }

            if (i >= pageContext.current - leftSide && i <= pageContext.current + rightSide) {
                items.push(i + 1);

                continue;
            }

            if (!(items[items.length - 1] == -1)) {
                items.push(-1);
            }
        }
    }

    return (
        <Fragment>
            <Helmet>
                { parse(pageContext.categoryEdge.node.yoast_head) }
            </Helmet>
            <Breadcrumbs breadcrumbs={ pageContext.categoryEdge.node.yoast_breadcrumbs } />
            <main>
                <section className={ classNames.category }>
                    <header className={ classNames.header }>
                        <h1 className={ classNames.title }>{ parse(pageContext.categoryEdge.node.name) }</h1>
                        <p className={ classNames.description }>{ parse(pageContext.categoryEdge.node.description) }</p>
                    </header>
                    { pageContext.current == 1 &&
                        <Fragment>
                            <PostImageGrid postEdges={ postEdges.slice(0, 4) } />
                            <PostGrid from={ 0 } postEdges={ postEdges.slice(4) } to={ Infinity } />
                        </Fragment>
                    }
                    { pageContext.current > 1 &&
                        <Fragment>
                            <PostGrid from={ 0 } postEdges={ postEdges } to={ Infinity } />
                        </Fragment>
                    }
                    <div className={ classNames.pagination }>
                        { pageContext.previous &&
                            <Link
                                className={ clsx(classNames.button, classNames.previousButton) }
                                to={ pageContext.basePath + "/" + pageContext.previous }
                            >
                                <FontAwesomeIcon icon={ faChevronLeft } />
                            </Link>
                        }
                        { items.map((item, index) => (
                            <Fragment key={ index }>
                                { item == -1 &&
                                    <span className={ classNames.break }>...</span>
                                }
                                { !(item == -1) &&
                                    <Link
                                        className={ clsx(classNames.button, {
                                            [classNames.currentButton]: item == pageContext.current
                                        } ) }
                                        to={ pageContext.basePath + "/" + item }
                                    >
                                        { item }
                                    </Link>
                                }
                            </Fragment>
                        )) }
                        { pageContext.next &&
                            <Link
                                className={ clsx(classNames.button, classNames.nextButton) }
                                to={ pageContext.basePath + "/" + pageContext.next }
                            >
                                <FontAwesomeIcon icon={ faChevronRight } />
                            </Link>
                        }
                    </div>
                </section>
            </main>
        </Fragment>
    );
};

export default Category;
